import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Title from "./Title"

const query = graphql`
  {
    allMarkdownRemark(
      limit: 5
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
        }
        fields {
          slug
        }
        id
      }
    }
  }
`

const Recent = () => {
  const data = useStaticQuery(query)

  const {
    allMarkdownRemark: {nodes: posts},
  } = data

  return (
    <Wrapper>
      <Title title="recent" />
      {posts.map(post => {
        const {title, date} = post.frontmatter
        const {slug} = post.fields
        return (
          <Link to={"/posts" + slug} key={post.id} className="post">
            <div>
              <h5>{title}</h5>
              <p>{date}</p>
            </div>
          </Link>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .post {
    margin-bottom: 1rem;
  }
  h5 {
    font-size: 0.7rem;
    margin-bottom: 0.25rem;
    letter-spacing: 0;
    line-height: 1.4;
    color: var(--clr-grey-1);
  }
  p {
    font-size: 0.6rem;
    margin-bottom: 0;
    color: var(--clr-grey-5);
  }
  .post:hover {
    h5 {
      color: var(--clr-grey-5);
    }
  }
`

export default Recent

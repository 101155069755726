import React from "react"
import styled from "@emotion/styled"

import Title from "./Title"

const About = () => {
  return (
    <Wrapper>
      <Title title="about" />
      <p>学んだことの備忘録です</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  p {
    color: var(--clr-grey-5);
    margin-top: 3rem;
  }
`

export default About

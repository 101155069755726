import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import CategoryTag from "./CategoryTag"

const query = graphql`
  {
    allMarkdownRemark {
      distinct(field: frontmatter___category)
    }
  }
`

const Categories = () => {
  const {
    allMarkdownRemark: { distinct },
  } = useStaticQuery(query)

  return (
    <Wrapper>
      {distinct.map((category, index) => {
        return (
          <li key={index}>
            <CategoryTag category={category} />
          </li>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  list-style: none;
  li {
    margin: 0;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
  }
`

export default Categories

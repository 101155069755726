import React from "react"
import styled from "@emotion/styled"

import About from "./About"
import Recent from "./Recent"
import BannerCategories from "./BannerCategories"

const Banner = () => {

  return (
    <div>
      <Wrapper>
        <About />
        <Recent />
        <BannerCategories />
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.aside`
  display: grid;
  grid-template-columns: 200px;
  justify-content: center;
  row-gap: 6rem;
  @media (min-width: 576px) {
    & {
      grid-template-columns: repeat(auto-fit, 200px);
      column-gap: 6rem;
    }
  }
`

export default Banner

import React from "react"
import Title from "./Title"
import Categories from "../Categories"
import { Link } from "gatsby"

const BannerCategories = () => {
  return (
    <div>
      <Link to="/categories">
        <Title title="categories" />
      </Link>
      <Categories />
    </div>
  )
}

export default BannerCategories

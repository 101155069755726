import React from 'react'
import { Link } from 'gatsby'
import { AiFillTag } from 'react-icons/ai'
import { css } from '@emotion/react'

const CategoryTag = ({category}) => {
  return (
    <>
      <Link to={`/${category}`} css={wapper}>
        <AiFillTag/>
        <span>{category}</span>
      </Link>
    </>
  )
}

const wapper = css`
  display: inline-block;
  position: relative;
  color: var(--clr-grey-5);
  svg {
    position: absolute;
    top: 30%;
  }
  span {
    text-transform: capitalize;
    margin-left: 1.5rem;
  }

  transition: var(--transition);
  border-radius: var(--radius);
  padding: 0.25rem 1rem 0.25rem 0.5rem ;
  &:hover {
    background: var(--clr-grey-9);
  }
`

export default CategoryTag
